.acquiringVialStatus .ui.progress:last-child {
  margin: 0;
}

.viewResults {
  margin-top: 2rem;
}

.dashboardButtonGroup h3{
  color: black
}

#veiwDashboard{
  overflow: auto;
  max-height: 600px
}

/*main container*/
#root > div > div > div.pushable > div.pusher > div{
  min-height: 980px;
  padding: 0em 0em;
  text-align: center
}


.app.split {
  overflow: hidden;
}

.popup {
  z-index: 1000 !important;
}

.segmentPadding {
  padding: 4em !important;
  border: none !important;
  border-radius: 1rem !important;
}

.segmentPadding:after {
  border: none !important;
}

.ui.stacked.segment:after {
  border: 0 !important;
}

.ui.left.sidebar, .ui.right.sidebar {
  width: 100% !important;
  max-width: 200px;
}


/* Used in search component */
.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pt1em {
  padding-top: 1em !important; 
}

.pb1em {
  padding-bottom: 1em !important; 
}
/* ######################### */


/* shared report */
#sharedCompanyInfo{
  vertical-align: top;
  white-space: pre-wrap 
}

#sharedCompanyInfo > a{
  overflow-wrap: break-word;
}
#sharedHeaderMenu {
  height: 100%
}
/*page not found || unathorized */
.redirects > div{
  max-width: 600px;
}
.redirects{
  height: 100vh;
}

#newUserForm{
  margin-left: 0px;
  margin-right: 0px;
}

#newUserForm > div{
  max-width: 600px
}

#resetPassword{
  height: 80vh;
  margin-left: 0px;
  margin-right: 0px;
}
#resetPassword > div{
  max-width: 600px
}

.ui.inverted.segment {
  background: transparent;
}
@media only screen and (max-device-width: 425px) {
  h1 {
    font-size: 1.5rem !important;
  }
}


@media only screen and (max-device-width: 425px) {

  .ui.breadcrumb {
    font-size: 0.75rem !important;
  }
  section.app {
    padding: .125rem !important;
  }
  .dynamicTable, .breadCrumb, .sample_report, .dashboard {
    margin: 0 auto !important;
  }
  .radioButtonGrid, .checkboxGrid {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
