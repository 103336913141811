.app.light h1 {
  color: black;
}

footer#main {
  margin-top: 2rem;
  padding: 0 0 2rem;
  
}

footer#main img {
  vertical-align: middle;
}

footer#main span {
  vertical-align: middle;
  margin-left: 1rem;
  color: var(--warmGreyTwo);
}

.samplePrep.contentBlock.customVial .prepMethodVial {
  width: 5rem;
  margin: 0 auto;
}

.samplePrep.contentBlock.customVial .preppedSample {
  width: auto;
}

.samplePrep.contentBlock.customVial .preppedSample .vialLabel {
  font-size: 1.5rem;
  top: 5.5rem;
}

@media only screen and (max-device-width: 425px) {

  footer#main {
    margin-top: 0.5rem;
    padding: 0 0 0.5rem;
  }
}
