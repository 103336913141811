.gridTypeImages img:hover {
  cursor: pointer;
  border-color: #ccc !important;
}

.gridTypeImages img.active {
  border: 3px solid var(--trueGreen) !important;
}

.dataSampleTable .dataTable .ui.grid .row>.column:last-child label + span, .dataShareSampleTable .dataTable .ui.grid .row>.column:last-child label + span {
    text-overflow: ellipsis;
    display: -webkit-box;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
