#sidebarCloserHittarget {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(1px);
  z-index: 100;
}

#sidebarMenu {
  .ui.checked.toggle.checkbox.driverPollToggle {
    input:focus:checked~label:before, input:checked~.box:before, input:checked~label:before {
      background-color: #21ba45 !important;
    }
  }

  .ui.toggle.checkbox input:focus~label:before, .ui.toggle.checkbox label:before {
    background: grey;
    background-color: grey;
  }
  
  .driverPollToggle {
    margin: 0;

    label {
      color: rgba(255,255,255,.9) !important;
    }
  }
}
