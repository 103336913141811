.form.screen {
  background-color: var(--white);
  margin: 1rem auto;
  /* width: 55rem; */
  padding: 1rem;
  border-radius: 1rem;
}

.ui.form .disabled.field, .ui.form .field.disabled > label, .ui.disabled.dropdown {
  opacity: 1 !important;
}

.ui.form .no-text-transform > label {
  text-transform: none !important;
}

.advancedFields {
  background-color: #ddd;
  border-radius: 0.2rem;
  margin: 2rem 0.5rem;
}

.advancedFields.open i.plus {
  display: none;
}

.advancedFields.open .advancedFields-inputs {
  display: block;
}

.advancedFields.closed i.minus {
  display: none;
}

.advancedFields.closed .advancedFields-inputs {
  display: none;
}

.ui.form .advancedFields .field>label {
  color: #777;
}

.advancedFields .advancedFields-inputs {
  padding: 1rem;
}

.advancedFields .advancedFields-add {
  cursor: pointer;
  padding: 0.5rem;
  text-align: left;
}

.advancedFields .advancedFields-add h3 {
  font-weight: 100;
  margin: 0;
  display: inline-block;
  color: #777;
}

.advancedFields .advancedFields-add i {
  display: inline-block;
  color: #777;
}

.ui.form .field>label {
  text-transform: uppercase;
}

.toggle .field {
  margin: 0 !important;
}

.ui.form .required-field.field > label:after, .ui.form .required-field.fields.grouped > label:after, .ui.form .required-field.fields:not(.grouped) > .field>label:after {
  margin: -.2em 0 0 .2em;
  content: '*';
  color: #db2828;
}

.results-search input {
  width: 30rem;
}

.ui.disabled.dropdown {
  opacity: 0.45 !important;
}


.toggle {
  margin-top: 1rem;
}

#myAccountLogoForm{
  display: None
}

#loginForm{
  height: 80vh;
  margin-left: 0px;
  margin-right: 0px
}
#loginForm > div{
  max-width: 600px
}

#newSampleTest .ui .grid{
  margin-top: 10px;
  text-align: right;
}

#newSampleTestButtonArea.ui.grid{
  float: right;
  text-align: center;
}

#thcProfile{
  display: none
}

#createReportForm.ui.grid{
  margin-top: 1em;
}

#createReportForm >div{
  overflow: auto; 
  max-height: 250px 
}

div#passwordResetForm {
  height: 80vh;
  margin-left: 0px;
  margin-right: 0px
}

#passwordColumn {
  max-width: 600px
}

#loadingLoginPage{
  min-height: 80vh;
}

.mani-top-label label{
  padding-top: 10px;
}

.mani-top-label {
	padding-bottom: 1rem;
}

#mani-image-buttons {
  padding-bottom: 35px;
}



.charCounter{
  text-align: right;
}

.paymentForm {
  padding: 0 !important;
}
.topRowPaymentForm{
  padding-bottom: 0 !important;
}

.paymentItem{
  font-weight: bold;
}
