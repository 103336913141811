#appHeader {
  position: fixed;
  top: 0;
  z-index: 99;
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  color: #eee;
  border-bottom: 1px solid #555;
}

#appHeader > * {
  width: 100%;
}

.headerUser {
  text-align: right;
  justify-content: flex-end;
  padding: 1rem;
  display: flex;

  .headerUser-info {
    margin-right: 1rem;
  }
}

.header-hamburgerAndLogo {
  text-align: left;
  display: flex;
  align-items: center;

  img {
    height: 1.75rem;
    margin: 0 1rem;
    cursor: pointer;
  }

  a {
    display: inline-block;
    height: 3.5rem;

    img {
      height: 100%;
    }
  }
}
