.menuPagination { 
  font-size: .6571429rem !important;
  padding-top: 0%;
}

.menu>.item {
  text-align: left;
}

@media only screen and (width: 320px) {
  .ui.menu .item {
    /* padding: 0%; */
  }
}

.menuSidebar {
  margin-top: '8px' !important;
}

.sampleActionsMenu {
  border-radius: 1.5em !important;
  color: var(--white) !important;
  background: #2185d0 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #2185d0), color-stop(100%, #19629b)) !important;
  background: -webkit-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -o-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -ms-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: linear-gradient(to bottom, #2185d0 0%, #19629b 100%) !important;
}

.sampleActionsMenu > .item {
  color: white !important;
}