.donutHolder {
  position: relative;
}

.donutHole {
  /* position: absolute; */
  color: var(--trueGreen);
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(-50%, -80%); */
  font-weight: bold;
  font-size: 3rem;
}

.circle-background, .circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #00b20d;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  fill: #00b20d;
}

.dataTable {
  color: var(--black);
}

.chart_menu_item:hover {
  cursor: pointer;
  background: rgba(0,0,0,.05) !important;
}


/* Results */
@media only screen and (max-width:576px) {
  text.highcharts-title {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
  }
}

@media only screen and (max-width:550px) {
  text.highcharts-title {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
  }
}

@media only screen and (max-device-width: 480px) {
  .dataTable {
    width: 100%;
  }
  .donutHole {
    /* display: none; */
    font-size: 1rem;
  }
}

@media only screen and (max-width:480px) {

  text.highcharts-title {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
  }

  text.highcharts-title tspan:nth-child(3) { display: none; }

  .dataTable .ui.grid>.column:not(.row):last-child, .dataTable .ui.grid>.row>.column:last-child {
    padding-left: 1rem !important;
  }
}

.atomSprite {
  display: block;
}
