.chromHolder .ui.progress {
  margin: 1rem 3rem !important;
}

.runningTest {
  margin: 2rem;
  padding: 2rem;
  margin-top: 0;
  padding-top: 0;
}

.runningTest .ui.progress:first-child {
  margin: 0;
}

.runningTest h1 {
  color: var(--white);
  margin: 0 0 1rem;
}

.stopWatch {
  color: white;
  font-family: 'Courier', sans-serif;
  margin-bottom: 1rem;
}

div div.chromHolder {
  width: 88%;
  margin-top: -12px;
}

.chromHolder {
  width: 100%;
  min-height: 400px;
}

@media only screen and (width: 1024px) {
  .createBatchTest {
    width: 43%;
  }
}