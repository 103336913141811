.loading-indicator:before {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: black;
  opacity: 0.5;
}

.loading-indicator:after {
  content: '';
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border-top: 10px solid rgba(22,196,233, 0.2);
  border-right: 10px solid rgba(22,196,233, 0.2);
  border-bottom: 10px solid rgba(22,196,233, 0.2);
  border-left: 10px solid #16c4e9;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.ui.progress .bar {
  transition: width 3s ease, background-color .1s ease;
  background-color: var(--trueGreen);
}

.loading-indicator .base-case {
  display: none;
}
