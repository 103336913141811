.contentBlock {
  background-color: var(--white);
  width: 100%;
  margin: 1rem auto;
  padding: 3rem 0;
  border-radius: 1rem;
}

.listBlock {
  padding: 0;
}

.timerHolder{
  margin: 3rem 1rem;
  border-radius: 1rem;
}

.listBlock ul {
  list-style: none;
  padding: 0;
}

.listBlock ul li:first-child a {
  border-radius: 1rem 1rem 0 0;
}

.listBlock ul li:last-child a {
  border-bottom: none;
  border-radius: 1rem;
}

.listBlock ul li a {
  color: #333;
  display: block;
  padding: 1rem 0;
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
}

.listBlock ul li a:hover {
  background-color: #e6e6e6;
}

.listBlock ul li a i {
  color: #999;
  margin-right: 0.5rem;
}

.homeBlock a {
  display: block;
  margin-bottom: 1rem;
}

.homeBlock a:last-child {
  margin-bottom: 0;
}

.contentBlock.blockWithHeader {
  padding-top: 0;
  border-top: 3.5rem solid #ccc;
  padding: 1rem 0 1rem 0;
}

.contentBlock.blockWithHeader h3 {
  margin-top: -3.5rem;
}

.contentBlock.blockWithHeader table {
  width: 100%;
  margin-bottom: 1rem;
}

.contentBlock.blockTable {
  padding: 0;
  overflow: hidden;
}

.contentBlock.blockTable a {
  text-align: center;
}

.contentBlock.login {
  width: 30rem;
  padding: 3rem;
}

.contentBlock.login .lds-facebook {
  display: none;
}

.contentBlock.login.loading form {
  display: none;
}

.contentBlock.login.loading .lds-facebook {
  display: inline-block;
}

.contentBlockNote {
  margin: 1.5rem 0 2.3rem;
}

.contentBlockNote span {
  color: var(--black);
}

.leftHome .contentBlock, .rightHome .contentBlock {
  padding: 4rem 4rem;
}
