#prepMethodPage h2 {
  text-align: left;
  margin-bottom: 20px;
}

.prepMethodTile {
  display: block;
  background-color: #eee;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
}

.acquireSelector {
  text-align: center;
}

.preppedVialInput {
  display: inline-block;
}

.preppedVialInput .vial {
  display: block;
  overflow: hidden;
}

button.setColor {
  padding: 1rem;
  border-radius: 100%;
  margin: 0 0.2rem;
  outline: none;
}

.vialPropertyPicker {
  width: 100%;
}

.prepMethodTile .header {
  position: relative;
  color: black;
  padding: 1.1em;
  border-bottom: 1px solid #000;
}

.prepMethodTile .header h3 {
  margin-bottom: 0;
}

.prepMethodTile .header .actions {
  position: absolute;
  right: 2rem;
  top: 1.2rem;
}

.prepMethodDiluentFlow {
  display: block;
  padding: 2rem;
}

.microliterValue {
  font-family: 'Libre Baskerville';
  font-size: 12pt;
  color: black;
}

.microliterValue:after {
  content: "μl";
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;
  margin-left: 3px;
}

.milliliterValue {
  font-family: 'Libre Baskerville';
  font-size: 12pt;
}

.milliliterValue:after {
  content: "ml";
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;
  margin-left: 3px;
}

.prepMethodDiluentFlow .vial .description .aliquotInfo {
  display: none;
}

.prepMethodDiluentFlow .vial .vial .description .aliquotInfo {
  display: block;
  width: 20rem;
  height: 100%;
  top: 41%;
  left: -8rem;
  text-align: left;
  color: black;
  position: absolute;
  color: #505150;
}

.prepMethodDiluentFlow .vial .description .aliquotInfo {
  display: none;
}

.prepMethodDiluentFlow .vial .description .aliquotInfo .transitionText {
  margin: 0 5px;
}

.prepMethodDiluentFlow .vial .description .aliquotInfo > * {
  display: inline-block;
}

.prepMethodDiluentFlow .vial .description {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

.prepMethodDiluentFlow .vial .description .prepMethodVial {
  height: 6rem;
  position: relative;
  padding: 0.8rem 1rem;
}

.prepMethodDiluentFlow .vial .description .prepMethodVial svg {
  height: 100%;
  position: relative;
  z-index: 1;
}

.prepMethodDiluentFlow .vial.editing .prepMethodVial:after {
  display: block;
}

.prepMethodDiluentFlow .vial #vial_contents {
  fill: rgba(0, 255, 0, 0.5);
}

.prepMethodDiluentFlow .vial > .vial #vial_contents {
  fill: rgba(0, 255, 0, 0.3);
}

.prepMethodDiluentFlow .vial > .vial > .vial #vial_contents {
  fill: rgba(0, 255, 0, 0.15);
}

.prepMethodDiluentFlow .vial {
  display: block;
  width: 100%;
  text-align: left;
  min-height: 5rem;
  position: relative;
  margin: 0;
}

.prepMethodDiluentFlow .vial > .vial {
  left: 21%;
  width: 100%;
}

.prepMethodDiluentComposer {
  background: #999;
}
