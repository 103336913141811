

.statusNode {
  position: relative;
  text-align: left;
}

.statusNode:after {
  position: absolute;
  right: 2rem;
  content: '>';
  color: var(--warmGreyTwo);
  font-size: 0.75rem;
}

.statusNode .statusLabel {
  text-transform: uppercase;
  color: var(--warmGreyTwo);
  font-size: 1rem;
  vertical-align: middle;
}

.statusNode .status {
  width: 1rem;
  height: 1rem;
  text-align: left;
  display: inline-block;
  border-radius: 1rem;
  border: 2px solid var(--black);
  vertical-align: middle;
  margin-right: 0.5rem;
}

.statusNode .status.OFF {
  background-color: var(--warmGreyTwo);
}

.statusNode .status.STARTING, .statusNode .status.NotReady {
  background-color: var(--yellow);
}

.statusNode .status.READY, .statusNode .status.Ready {
  background-color: var(--trueGreen);
}

#runStatus {
  color: var(--dustyOrange);
  font-size: 1.25rem;
  line-height: 1.25;
  border: 1px solid var(--dustyOrange);
  /* width: 50%; */
  margin: 0 auto;
  padding: 1rem;
}

#runStatus > p {
  color: var(--dustyOrange);
}

.systemStatusSteps {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
