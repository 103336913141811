$activeMapper: #2185d0;

.importCsvMapperPage {
	.sampleActionsMenu {
		background: gray !important;
	}

	.displayContainer {
		padding: 20px 10px;

		.dropZone {
			cursor: pointer;

			.dropZoneSegment {
				background-color: lightgray;
			}
		
			.zone {
		
				&.active {
					background: white;
				}
		
				&.error {
					background: red;
					color: white;
				}
			}
		}

		.mappingControlColumn {
			.mappingControl {
				overflow: auto;
				max-height: 55vw;

				.analyteColumnsContainer {
					padding: 3px;
					
					.analyteColumnLabel {
						&:hover {
							cursor: pointer;
						}
					}

					.analyteColumnItem {
						margin-top: 5px;
						margin-bottom: 2px;
	
						.analyteColumnValue {
							padding-left: .2em;
							padding-right: .2em;
							border-radius: 4px;

							&.actionable {
								&:hover {
									cursor: pointer;
									font-weight: bold;
								}
							}
						}
					}
				}

				.activeControl {
					border-left: .5px solid $activeMapper;
					border-right: .5px solid $activeMapper;
					background-color: rgba(210,230,245,1);
					color: black;
					font-weight: bold;
				}

				.controlLabel {
					float: left;
				}

				.controlValue {
					float: right;
					padding: .2em;
					border-radius: 4px;
				}
			}
		}

		.mappingCsvColumn {
			div {
				overflow: auto;
				max-height: 65vw;

				.csvTable {
					border-collapse: collapse;

					.mappingRow {

						.rowNumber {
							background: #f9fafb;
						}

						.mappingCell {
							white-space: break-spaces;
						}

						.mappingCell.mapperActive {
							&:hover {
								cursor: pointer;
								border: 1px solid $activeMapper;
							}
						}
					}

					.mappingRow.mapperActive {
						&:hover {
							cursor: pointer;
							border: 2px solid $activeMapper !important;
						}
					}
				}
			}
		}
	}

	.mappingForm {
		.fields {
			justify-content: center;
			margin-bottom: 0;
		}
	}
}