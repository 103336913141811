.widgets {
  margin-top: 0.5rem;
}

.widgetLeft, .widgetRight {
  width: 49%;
  width: 100%;
}

.widgetLeft {
  float: left;
}

.widgetRight {
  float: right;
  display: none;
  width: 47.8%;
}

.widget img {
  width: 100%;
}

.widget {
  margin-bottom: 1.8rem;
}

.widgetHolder h3 {}

.widgetsHeader {
  margin-bottom: 2rem;
}

.widgets .widgetsHeader h4 {
  color: var(--white);
  text-transform: uppercase;
  margin-top: 0.5em;
}

.widgets .widgetsHeader i.icon {
  color: var(--white);
}

.widgets .widgetsHeader .input {
  display: none;
}

.widgets .widgetsHeader .icon.input input {
  border-radius: 1.5em;
  width: 32rem;
}

.widgets .widgetsHeader .icon.input i.icon {
  color: var(--trueGreen);
}

.widgetPadding {
  padding-top: 0 !important;
}

.widgetMarging {
  margin:0 !important;
}

@media only screen and (max-device-width: 768px) {
  .homepage_bar_chart_widget {
    margin-top: 1rem !important;
    padding: 0 !important;
  }
}