.rawDataPage {
	.tabbedDisplay {
		.segment {
			max-height: 75vw;
			overflow: auto;

			.summaryTop {
				padding-bottom: 1rem;
				border-bottom: 1px dashed rgba(34,36,38,.15);
			}

			.summaryBottom {
				padding-top: 1rem;
			}
		}
	}
}

.sampleInfo {
	.sampleImageUploadDisplay {
		padding-top: 1rem;
		padding-bottom: 1rem;

		img {
			max-width: 90%;
			margin: 0 auto;
		}
	}
}

.coaImage {
	img {
		max-width: 100%;
		padding: 1rem;
	}
}

.chartsColumn {
	padding-top: .5rem !important;
	padding-bottom: .5rem !important;
}