// "/samples/report-methods" page lists global & org methods available for editing
.methodsListPage, .createSampleReportMethod {
    .methodDisplay {
        .analytePill {
            color: white;
            cursor: initial;

            &:hover, &:focus {
                color: white;
            }
        }
    }
}

// "/samples/report-methods/:id/edit" page for updating attributes of a method
// "/samples/report-methodscreate" page for creating attributes of a method
.editMethodPage, .createMethodPage {
    .methodFields {
        justify-content: center;
    }

    .analytesEditor {
        .analytePill {
            color: white;
        }

        .meta {
            margin-bottom: 20px;;
        }

        .attachCalcRow {
            &:hover {
                background-color:rgba(0,0,0,.05);
            }

            .checkbox {
                margin-top: 0;
            }
        }
    }

    .submitMethodButton {
        display: block;
        margin: 0 auto;
        margin-top: 10px;
    }
}

.createAnalyteContainer {
    .field {
        .ui.input {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .buttonsContainer {
        margin-top: 8px;

        .moveLeft {
            float: left;
        }
        .removeButton {
            display: block;
            margin: 0 auto;
        }
        .moveRight {
            float: right;
        }
    }
}