.table-wrapper {
  overflow: auto;
}

.table-wrapper .ui.celled.table td, .ui.celled.table th {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-wrapper .ui.celled.table tr th {
  background-color: #f9fafb;
  position: sticky;
  top: -1px;
  z-index: 2;
}

.table-wrapper .ui.celled.table tr th:first-of-type {
  left: 0;
  z-index: 3;
}

.table-wrapper .ui.celled.table tbody tr td:first-of-type {
  background-color: #f9fafb;
  position: sticky;
  left: -1px;
}

.table-wrapper .ui.celled.table tr {
  display: table-row !important;
  cursor: initial;
}

.table-wrapper .ui.sortable.table thead th.sorted:hover {
  background-color: #f9fafb;
}

.table-wrapper table tbody tr.totals-row {
  background: #f9fafb !important;
}
