.user-inactive {
  color:red !important;
}

.user-active {
  color:green !important;
}

.user-Details {
   margin-right: 4px !important;
   color:black  !important;
   width:inherit !important;
}

i.user-inactive {
  color:red !important;
}

i.user-active {
  color:green !important;
}

.myAccount .img {
  border: 1px solid var(--black) !important;
  padding: 0 !important;
}

.userInfo {
  text-transform: capitalize !important;
}

.headerUser > div {
  background: rgb(51, 51, 51) !important;
}

.headerUser > div > .item {
  padding-left: 8px !important;
  padding-right: 8px !important;
  background-color: rgb(51, 51, 51) !important;
  border: 0 none;
}

.headerUser > div > .item:before {
  background: none !important;
}