.tablita table {
  margin: 0 auto;
  color: var(--white);
  border-collapse: collapse;
  margin-top: 1rem;
}

.tablita .positiveResult {
  color: green;
}

.tablita .negativeResult {
  color: red;
}

.tablita table thead td {
  border-bottom: 1px solid var(--white);
}

.tablita table td {
  padding: 1rem;
}

.tablita table tr.emptyCompound {
  color: var(--warmGrey);
}

.tablita table tbody tr:hover td {
  background-color: rgba(255, 255, 255, 0.075);
}