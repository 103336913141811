
.qcResultsTable__actionsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-around;

	button {
		flex-grow: 1
	}
}