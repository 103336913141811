li a {
  cursor: pointer;
}

 #settingsIcon {
 	color: gray;
 	line-height: 23px
 }

#sharedHeaderIcon {
	height: 100%;
	margin-right: 10px
}
