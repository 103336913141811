.samplePrep.contentBlock {
  padding: 2rem 0 3rem;

  .preppedSample {
    width: 3rem;
    position: relative;
    margin: 0 auto;

    .vialLabel {
      position: absolute;
      top: 3rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
      text-shadow: -.75px 0 rgba(255,255,255,.65), 0 .75px rgba(255,255,255,.65), .75px 0 rgba(255,255,255,.65), 0 -.75px rgba(255,255,255,.65);
    }

    .dilutionFactor {
      font-weight: bold;
      text-align: center;
      color: black;
    }
  }
}


.preparedSamplesInput {
  overflow: hidden;

  .vial.vialLink {
    &:hover {
      cursor: pointer;
      background-color: lightgray;
      border-radius: 5px;
    }
  }

  .preppedSample {
    width: 3rem;
    float: left;
    position: relative;
    margin: .5rem 1rem;

    .vialLabel {
      position: absolute;
      top: 3rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
	  font-size:0.8rem;
      text-shadow: -.75px 0 rgba(255,255,255,.65), 0 .75px rgba(255,255,255,.65), .75px 0 rgba(255,255,255,.65), 0 -.75px rgba(255,255,255,.65);
    }

    .dilutionFactor {
      font-weight: bold;
      text-align: center;
      color: black;
    }
  }

  .addButton {
    cursor: pointer;
    float: left;
    color: black;
    margin: 24px;
    padding-top: 10px;
    background: #ccc;
    width: 3rem;
    height: 3rem;
  }
}

.addPreparedSample {
  float: left;
  text-align: center;
  margin: 0 1rem;

  // input[type=text] {
  //   text-align: center;
  // }

  button.setColor {
    cursor: pointer;
  }

  button.setColor:disabled {
    border-color: black;
    position: relative;

    &:after {
      content: '✔';
      position: absolute;
      left: 50%;
      top: 50%;
      color: black;
      opacity: 80%;
      font-size: 3rem;
      line-height: 1rem;
      margin-top: -0.4rem;
      margin-left: -1rem;
    }
  }
}

.prepMethodEditTile .content {
  position: relative;
}

.prepMethodTile.prepMethodEditTile {
  background: #f8ffd4;

  h3 input {
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
  }

  .prepMethodDiluentFlow {
    .vial .prepMethodVial {
      background: white;
      border-radius: 1em;
      cursor: pointer;

      &:after {
        display: none; // override with hover
        content: "✎";
        font-size: 2.5rem;
        width: 1rem;
        height: 1rem;
        color: orange;
        position: absolute;
        right: -1.5rem;
        left: auto;
        top: 1rem;
      }

      &:hover {
        background: #bbb;
        z-index: 3;

        &:after {
          display: block;
        }
      }
    }
  }
}

.prepMethodDiluentComposer {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  background: rgba(255,255,255, 0.87);
  backdrop-filter: blur(2px);
  display: flex;
  flex-wrap: wrap;
}

.prepMethodDiluentComposer .diluentVial {
  width: 5rem;
}

.vialPropertyPicker-holder {
  width: 100%;
}

.prepMethodDiluentComposer footer {
  flex-basis: 100%;
}

.diluentProccessStep {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: black;
  flex-grow: 1;

  &.fromThisVial {
    padding: 1rem 5rem;
  }

  &.toThisVial {
    flex-wrap: wrap;
  }

  &.transmission  {

    display: flex;

    .holder {
      position: relative;

      &.input-holder {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    svg {
      width: 4rem;
    }

    .microliterValue {
      bottom: 0;
      right: 0;
      width: 1.5rem;

      &:after {
        line-height: 4rem;
      }
    }

    .pipetteSVG-holder {
      margin-left: 1rem;
    }

    .input-holder {
      position: relative;

      .bgNumber {
        position: absolute;
        line-height: 1;
        color: rgba(0, 0, 0, 0.3);
        top: 0;
        right: 0;
        z-index: 1;
        padding-right: 1.6rem;
        margin-top: -2px;
        text-align: right;
        font-size: 3rem;
        .userInput {
          color: transparent;
        }
      }
    }

    input {
      font-size: 3rem;
      width: 2.5em;
      height: 1em;
      text-align: right;
      background: transparent;
      border: none;
      outline: none;
      position: relative;
      z-index: 3;
    }
  }
}

.prepMethodCannIdTile {
  .header {
    padding: 1rem;
    border-bottom: 1px solid black;
  }

  .prepMethodDiluentFlow {
    display: flex;
    justify-content: center;

    .vialContainer {
      display: flex !important;
      align-items: center;

      .prepMethodVial {
        width: 12rem;

        svg {
          width: 5rem;
        }
      }

      .aliquotInfo {
        display: flex;
      }
    }
  }
}