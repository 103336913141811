
#shareReportCannIdLogo{
  width: 150px;
  margin-bottom: 38px
}

#shareQR{
  margin-bottom: 10px;
  margin-left: 30px;
  width: 100px;
  height: 100px
}

#shareReportLogo{
  border: 1px solid black;
  height: 100px;
  width: 100px;
  margin-right: 30px
}

@media only screen and (max-device-width: 768px) {
  #shareReportCannIdLogo {
    margin-bottom: 0 !important;
  }
}

.menuLogo {
  margin: 6px !important;
}

.navLogo {
  width: 120px !important;
}

#myAccountLogo .img{
  width: 20em;
  height: 15em
}

footer#main img{
  width: 30px
}

@media only screen and (max-width:767px){
  .ilLogo .ui.secondary.inverted.pointing.menu .item { padding: 0 !important; }
  .ilLogo .ui.menu .item>i.icon { margin: 0 auto !important; }
  /* Table */
  /* .dynamicTable .sixteen .grid > .sixteen { overflow-x: auto; }
  .dynamicTable .sixteen .grid > .sixteen table { width: 850px;}
  .dynamicTable .sixteen .grid > .sixteen table thead { width: 100%; display: block;}
  .dynamicTable .sixteen .grid > .sixteen table tbody tr { display: table-row-group !important}
  .dynamicTable .sixteen .grid > .sixteen table thead.full-width th { width: 100% !important;  max-width: 100%; display: block !important;}
    .dynamicTable .sixteen .grid > .sixteen table thead th, .dynamicTable .sixteen .grid > .sixteen table tbody td {
    display: table-cell !important;
    width: 130px !important;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;  
  }
  .dynamicTable .sixteen .grid > .sixteen table{ 
    width: 700px;
  }*/
}