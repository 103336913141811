// sass for all certus related components
.sampleCertus {
	border: 1px solid black;
	border-radius: 1rem;
	
	.displayGrid {
		padding: 1rem;

		.descriptorGrid {
			border: 1px solid gray;
			border-radius: 1rem;
			padding: 1rem;
			cursor: pointer;
			box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 30%) !important;

			&:hover {
				background-color: rgba(77,152,81,.1);
			}
			.certusLogoContainer {
				img {
					margin: 0 auto;
				}

				h2 {
					margin-bottom: 0;
					i {
						vertical-align: super;
					}
				}
			}
		}
	}

    .createGrid {
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .certusLogo {
        max-width: 100px !important;
        overflow: auto;
    }
}

.certusList {
    .titleGrid {
        display: flex;
        align-items: center;
        justify-content: center;

        .certusLogo {
            max-width: 90px !important;
            // margin: 0 auto;
        }
    }

    .descriptorContainer {
        border: 1px solid black;
        margin: 5px;
        border-radius: 1rem;

        .descriptorGrid {
            display: flex;
            align-items: center;
            justify-content: center;

            .certusLogo {
                max-width: 75px !important;
                overflow: auto;
            }
        }
    }

    .detailsList {
        text-align: left;
        display: inline-block;
    }

    .certusSelectorTable {
        .certusListRow {
            cursor: pointer;

            &:hover {
                background-color: rgba(77,152,81,.1);
            }
        }
    }
}

.certusScannerLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .label {
        margin-top: .5rem;
    }
}

.certusCreate {
    .createCertusForm {
        .transportationForm, .projectForm {
            .fields {
                justify-content: center;
            }

			.manifestFormCell {
				word-break: break-word;
			}

			.imageDropZone {
				img {
					max-width: 100%;
				}
			}
        }

        .projectForm {
            justify-content: center;
        }

        .selectedCertusList {
            border: solid 1px #21ba45;
            border-radius: 1rem;
        }

        .errorMessagesGrid {
            .ui.error.message {
                display: block;
            }
        }
    }
}

// tables listing clickable certus: certus list, create & display project and transportation certi
.certusSelectorTable {
    margin-top: 15px;
    overflow: auto;

    .selected-certus-row {
        color: #4d9851;
    }
    
    .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
        background-color: #21ba45 !important;
    }
}

// associated certus qr modal
.associatedCertiGrid {
    .qrGrid {
        margin: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .qrItem {
            margin-bottom: 1rem !important;
        }
    }
}

// chemical report and coa toggle in create sample certus modal
.ui.checked.toggle.checkbox.certusModalToggle {
    input:focus:checked~label:before, input:checked~.box:before, input:checked~label:before {
        background-color: #21ba45 !important;
    }
}

.certusLogoWhite {
	.st1, .st2 {
		stroke: white;
	}
}

// little sample action button on sample results table to open
// associated certus modal or create certus modal
.certusLogoActionButton {
    padding: 3px 4px  3px 4px!important;
    transform: translate(0px, 4px);

    &.associated {
        .st1, .st2 {
            stroke: white;
        }
    }

    &.create {
        background-color: #E0E1E2;

        .st1, .st2 {
            stroke: #525252;
        }

        &:hover {
            background-color: #E0E1E2;
        }
    }

    svg {
        width: 21px;
        transform: translate(0px, 1px);
    }

    @media (max-width: 992px) {
        transform: translate(0px, 4px);

        svg {
            width: 18px;
        }
    }
}

// certus action buttons modal
.certusConfirmation {
    .react-datepicker-wrapper {
        width: initial;
        margin-right: 8px;
        border: none;
    
        &:focus-visible {
          outline: none;
        }
    
        .react-datepicker__input-container {
          border: none;
    
          &:focus-visible {
            outline: none;
          }
    
          input {
            height: 100%;
            border: solid 1px rgba(34, 36, 38, 0.15);
            border-radius: 4px;
            padding: 9.5px 14px;
            cursor: pointer;
    
            &:focus-visible {
              border: none;
              outline: solid 1px #85b7d9;
            }
          }
    
          input::placeholder {
            color: rgba(100, 100, 100, .4);
          }
        }
      }
}
