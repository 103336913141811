body {
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
}

section.app {
  padding: 1rem;
}

body {
  background-color: var(--blackTwo);
}

.app.light em {
  color: black;
}

.ui.fitted.toggle.checkbox {
  vertical-align: middle;
  margin: 0;
}

.betaFeatures {
  margin-top: 2rem;
}

.betaFeatures em {
  margin-right: 1em;
}

/* ######################### */

@media only screen and (max-device-width: 425px) { 
  
  h1 {
    font-size: 1.5rem !important;
  } 
}

/* Header */


