body {
	article.markdown {
		color: var(--black);
		padding: 0.5rem;

		img {
		width: 100%;
		margin-top: 1rem;
		}
	}

	.imageDropZone {
		flex: 0 0 90%;
	}

	#newSampleTest {
		.sampleImageUploadDisplay {
			margin: 0 !important;

			.imageDropZone {
				flex: initial;
				width: 100%;
				text-align: center;

				img {
					max-width: 100%;
				}
			}
		}
	}

	.dragonDrop {
		width: 200px !important;
		margin: 0 auto;
	}
}
