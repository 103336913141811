.equations {
  color: var(--warmGrey);
}

.equations p {
  margin: 0;
}

.equations h3 {
  margin-bottom: 0.5rem;
}

.equationsBox {
  padding: 1rem;
  /* width: 50%; */
  margin: 0 auto;
  border: 1px solid var(--warmGrey);
}

/* equations drop down menu in samples */
.accordian {
  border: 1px solid var(--black) !important;
  border-radius: 1rem !important;
  padding: 1px !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3);
}

.accordian-title {
  border-bottom: 1px solid var(--black) !important;
  font-family:Open Sans, sans-serif !important;
}

.accordian-inactive {
  font-family:Open Sans, sans-serif !important;
}

.ui.accordion:not(.styled) .title~.content:not(.ui):last-child {
  padding-bottom:  1em !important;
}