.queueManager {
  position: fixed;
  background: var(--omgAreYouSeriousBlack);
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transition: transform 2s ease-in-out;
  transform: translate3d(0, -100%, 0);

  &.open {
    transition: transform 2s ease-in-out;
    transform: translate3d(0, 0, 0);
  }

  .acquisitionTools {
    margin: 2rem 0;
  }

  .queuesHolder {
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
}

body.queueOpen {
  height: 100%;
  overflow: hidden;
}

.autosamplerActions .actionButton {
  display: inline;
  margin: 0 0.1rem;

  .spinner.large.loading.icon {
    background-color: initial;
  }
}

.autosamplerActions .actionButton .button.error {
  background: red;
  color: white;
}

.autosamplerTable {
  width: 50%;
  max-height: 100%;
  counter-reset: sample_queue;
  padding-bottom: 12rem;
  overflow-y: scroll;


  .handyActions {
    margin-top: 2rem;
  }
}

.queueManager .canvas-container {
  width: 50%;
}

.canvas-container {
  width: 50% !important;
}

.sampleQueueList {
  text-align: left;
  padding: 0.5em 1rem;
  color: white;
}

.sampleQueueList .endOfQueueToggles {
  display: inline-block;
}

.sampleQueueList .endOfQueueToggles .queueMessage {
  margin: 0 1rem;
}

.sampleQueueList .endOfQueueToggles .queueMessage:first-child {
  margin-left: 0;
}

.sampleQueueList .endOfQueueToggles .toggle {
  cursor: pointer;
  padding: 0 1em;
  border-radius: 1em;
}

.sampleQueueList .endOfQueueToggles .toggle:hover {
  background-color: rgba(245, 139, 35, 0.3)
}

.sampleQueueList .endOfQueueToggles .toggle.current {
  cursor: text;
  background-color: var(--dustyOrange);
  color: black;
}

.queueInfo .sampleName:before {
  counter-increment: sample_queue;
  content: counter(sample_queue);
  margin-right: 1em;
}

.acquisitionTools {
  width: 100%;
  color: white;
}

.noExey .remove {
  display: none;
}

.autosamplerActions .icon{
  cursor: pointer;
}

.acquiringVialStatus {
  color: white;
}

.autosamplerWidget {
  display: block;
  text-align: center;
  cursor: pointer;
}

.queueOpen .autosamplerWidget {
  display: none;
}

.sampleQueueList .vialIcons {
  float: right;
  width: 10rem;
}

.sampleQueueList:nth-child(odd) {
  background-color: #333;
}

.autoSample {
  width: 100%;
  display: block;
  cursor: pointer;
}

.autoSample.current {
  text-decoration: underline;
}

.queueHeaderSection {
  display: flex;
  align-items: center;
  justify-content: center;

  .progress {  
    background-color: #f0f0f0;
  }

  .autosamplerActions {
    .acquiringVialContainer {
      margin-top: 8px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .miniBottles, .autosamplerPlayButton, .autosamplerPauseButton, .autosamplerStopButton, .autosamplerActions {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.notificationsLog {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  .message {
    min-width: 50%;
    max-width: 80%;
  }
}