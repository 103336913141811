#dashHeader {
  overflow: hidden;
  /* margin-top: 2rem; */
  margin-bottom: 0.75rem;
}

#dashHeader.plainHeader {
  background-color: var(--omgAreYouSeriousBlack);
  z-index: 1000;
  position: relative;
}

#dashHeader .third h3, #dashHeader .third h4 {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
}

#dashHeader .third h3 {
  color: var(--whiteTwo);
  letter-spacing: 1px;
  font-size: 1.75rem;
  margin-bottom: 0.3rem;
}

#dashHeader .third h4 {
  color: var(--pinkishGrey);
  text-transform: uppercase;
  font-size: 1rem;
}

#dashHeader .logo img {
  width: 80px;
  margin-bottom: 0.5rem;
}

#dashHeader .ilLogo {
  text-align: left;
}

#dashHeader .ilLogo img {
  width: 100px;
  height: 100%;
}

#dashHeader .actions {
  text-align: right;
  padding-right: 1rem;
}

#dashHeader .actions .ui.dropdown .text {
  color: var(--whiteTwo);
}

#dashHeader .actions .ui.dropdown .menu .text {
  color: var(--warmGrey);
}

#dashHeader .actions .ui.dropdown .menu {
  right: 0;
  top: 2rem;
}

#dashHeader .actions .actionsSeparator {
  width: 1px;
  height: 1.5rem;
  background-color: var(--black);
  display: inline-block;
  vertical-align: middle;
  margin: 0 1rem;
}

#dashHeader .actions .labeledIcon.light i.icon {
  color: var(--whiteTwo);
}

#dashHeader .actions .labeledIcon.light span {
  color: var(--whiteTwo);
}

#dashHeader .actions .labeledIcon.dark i.icon {
  color: var(--warmGrey);
}

#dashHeader .actions .labeledIcon.dark span {
  color: var(--warmGrey);
}


.headerColor {
  color:white !important;
}

.subheader {
  padding: 0 !important;
  margin: 0 !important;
}

div.mobilePhaseHeader {
  width: 100%;
}

@media (max-width: 480px) {
  .logoutDesktop {
    display: none !important;
  }

  .dashboardHeader h3 {
    font-size: 1.5rem;
  }

  .dashboardHeader h4 {
    font-size: 0.9rem
  }

  .dashboardHeader img {
    width: 60px;
  }

}

@media (min-width: 480px) {
  .logoutMobile {
    display: none !important;
  }
}

@media only screen and (max-width:480px) {
    /* Header */
  #dashHeader .ui.grid > div.two {
    position: absolute;
    padding: .0;
    top: 20px;
    z-index: 1;
  }

  #dashHeader .ui.grid > div.eight {
    padding-left: 0;
    padding-right: 0;
    width: calc(100% - 80px) !important;
    width: -webkit-calc(100% - 80px) !important;
    width: -moz-calc(100% - 80px) !important;
    width: -ms-calc(100% - 80px) !important;
    width: -o-calc(100% - 80px) !important;    
    margin: 0 auto;
    text-align: center;
  }

  #dashHeader .ui.grid > div.eight .right.item {
    float: none;
    margin: 0 auto;
    justify-content: center;
    top: 3px;
    left: 0px;
    right: 0;
  }
}

#demo_banner {
  padding: 0.75rem 0;
  background-color: #ccc;
  color: var(--white);
}
