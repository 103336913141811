.samplePrep.contentBlock {
  width: 10rem;
}

.samplePrep.contentBlock img {
  width: 70%;
  margin: 2rem 0;
}

.samplePrep.contentBlock img.fullWidth {
  width: 100%;
}

.samplePrep.contentBlock h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.samplePrep p {
  text-align: left;
  font-size: 1.1rem;
}

