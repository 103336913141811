#mainContent {
  margin: 6rem 6% 0px;
}

#mainContent {
  /* width: 100%; */
}

#mainContent > section > h1{
	color: black;
}

@media only screen and (max-device-width: 425px) {
  #mainContent {
    margin: 0 !important;
  }
}
