$activeMapper: #2185d0;

//
// ImportSample
//
.importSamplePage .intakeForm {
	margin-bottom: 15px;

	form {
		.fields {
			display: flex;
			flex-direction: row;
			width: 100%;
			margin: 0 0 1em 0;

			// flex-grow to fill space without deliberately assigning allocation
			.inputter {
				flex-grow:2;
			}

			.import-notes {
				textarea {
					resize: none;
				}
			}
		}
	}
}

.importSamplePage .dropZone {
	cursor: pointer;
	padding: 20px 10px;

	.zone {
		background: #ccc;
		padding: 2rem;
		border-radius: 2rem;
		text-align: center;
		margin: 0 14px 14px 14px;

		&.active {
			background: white;
		}

		&.error {
			background: red;
			color: white;
		}
	}
}

.importSamplePage .previewSegment {
	.summaryTop {
		padding-bottom: 1rem;
		border-bottom: 1px dashed rgba(34,36,38,.15);
	}

	.summaryBottom {
		padding-top: 1rem;
	}

	.previewVialInput {
		width: 100%;
	}

	.segment.active.tab {
		overflow: auto;
		max-height: 50vw;

		.csvTable {
			border-collapse: collapse;

			.mappingRow {
				.rowNumber {
					background: #f9fafb;
				}
			}
		}
	}

	form {
		.fields {
			display: flex;
			flex-direction: row;
			width: 100%;
			margin: 0 0 1em 0;

			// flex-grow to fill space without deliberately assigning allocation
			.inputter {
				flex-grow:2;
			}

			.import-notes {
				textarea {
					resize: none;
				}
			}
		}
	}
}

.importConfirmation {
	.import-full-terms {
		cursor: pointer;
		color: rgba(0,0,0,.6);
		margin-left: 5px;
	}

	p {
		padding: 8px;
	}
}

// 
// ImportTemplateEdit
// 
.templateEditForm {
	.fields {
		justify-content: center;
		align-items: center;
	}

	.fields:first-child {
		align-items: end;
	}
}

.templateOriginal {
	overflow: auto;
	max-height: 55vw;
}

// 
// ChemstationImport
// 
.fitlerRunsDate {
	cursor: pointer;
}

.filterRunsContainer {
	display: flex;
	justify-content: center;
	align-items: center;

	.ui.input {
		margin-right: 8px;
	}

	.react-datepicker-wrapper {
		width: initial;
		margin-right: 8px;
		border: none;

		&:focus-visible {
			outline: none;
		}

		.react-datepicker__input-container {
			border: none;

			&:focus-visible {
				outline: none;
			}

			input {
				height: 100%;
				border: solid 1px rgba(34, 36, 38, 0.15);
				border-radius: 4px;
				padding: 9.5px 14px;
				cursor: pointer;

				&:focus-visible {
					border: none;
					outline: solid 1px #85b7d9;
				}
			}

			input::placeholder {
				color: rgba(100, 100, 100, .4);
			}
		}
	}
}

//
// ParsedCsvDisplay
//
.parsedCsvContainer {
	.csvTools {
		padding: 1em;
		display: flex;
		align-items: center;
	}

	.segment {
		.list {
			.item {
				cursor: initial;
				text-shadow: -.75px 0 rgba(255,255,255,.65), 0 .75px rgba(255,255,255,.65), .75px 0 rgba(255,255,255,.65), 0 -.75px rgba(255,255,255,.65);
			}
		}
	}

	.csvTable {
		border-collapse: collapse;

		.mappingRow {

			.rowNumber {
				background: #f9fafb;
			}

			.mappingCell {
				white-space: break-spaces;
			}

			.mappingCell.mapperActive {
				&:hover {
					cursor: pointer;
					border: 1px solid $activeMapper;
				}
			}
		}

		.mappingRow.mapperActive {
			&:hover {
				cursor: pointer;
				border: 2px solid $activeMapper !important;
			}
		}
	}
}