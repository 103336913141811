table.table.selectable tr {
  cursor: pointer;
}

table.table.selectable .pagination {
  display: none;
}

.dataTable {
  background-color: var(--white);
  width: 65%;
  margin: 0rem auto;
  padding: 1rem 0;
  border-radius: 1rem;
}
.dataSampleTable {
  background-color: var(--white);
  margin: 0 1em;
  padding: 1rem 0;
  border-radius: 1rem;
  border:1px solid black;
}

.dataSampleTable>.dataTable {
  width: 100% !important;
}

.dataShareSampleTable>.dataTable>.grid {
  border-radius: 1rem;
  border:1px solid black;
}

.dataShareSampleTable>.dataTable>.ui.grid>.row>.column {
  text-align: center !important;
}

.dataTable .ui.grid>.column:not(.row), .dataTable .ui.grid>.row>.column {
  padding: 1rem;
}

.dataTable label {
  font-weight: bold;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: block;
}

.table.table.selectable .pagination {
  display: flex !important;
}

/* 04-11-2019 Table alignments */
@media only screen and (max-width:992px){
  .dynamicTable table .ui.centered.grid > .six.wide.computer.six.wide.large.screen.sixteen.wide.mobile.six.wide.tablet.column, 
  .dynamicTable .selectable .twelve.wide.computer.twelve.wide.large.screen.sixteen.wide.mobile.twelve.wide.tablet.column.dataTableHeaderButtons,
  .dynamicTable .selectable .four.wide.computer.four.wide.large.screen.sixteen.wide.mobile.four.wide.tablet.column { width: 100% !important; }  
  .dynamicTable table .ui.centered.grid > .ten.wide.computer.ten.wide.large.screen.sixteen.wide.mobile.ten.wide.tablet.column.dataTableHeaderButtons { width: 100% !important; }
  .dynamicTable .sixteen.wide.computer.sixteen.wide.large.screen.sixteen.wide.mobile.sixteen.wide.tablet.column .ui.table td { padding: .78571429em .48571429em; }
  .dynamicTable .sixteen.wide.computer.sixteen.wide.large.screen.sixteen.wide.mobile.sixteen.wide.tablet.column .ui.table td button { font-size: 0.86rem;}
}