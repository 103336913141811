#modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background: rgba(0, 0, 0, 0.85);
  z-index: 10;
}

#modal h1 {
  font-size: 4rem;
  margin-top: 10rem;
}

/*#share-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

#share-modal h2 {
  margin: 0;
}



#share-modal .share-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: var(--blackTwo);
  padding: 5rem;
  border-radius: 1rem;
}*/

#share-modal{
  text-align: center
}
/* share chart*/
#share-modal > div.content{
  text-align: center;
}
#testResultsModal > div.content{
  text-align: center;
}


#sampleTestResult{
  padding-bottom:0px;
}

