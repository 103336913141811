#changelog-page {
  text-align: left;

  h1 {
    margin: 0 40px;
    color: #049d1f;
  }

  figure {
    background-color: var(--white);
    border-radius: 0.5rem;

    header {
      font-size: 2rem;
      padding: 1rem 1rem 0.5rem;
      color: var(--black);
      border-bottom: 1px solid var(--omgAreYouSeriousBlack);

      h2 {
        display: inline-block;
        font-style: none !important;
        padding: 0;
        margin: 0;
      }

      span {
        display: inline-block;
        font-size: 1rem;
        font-style: italic;
        vertical-align: middle;
      }
    }

    article {
      color: var(--black);
      padding: 0.5rem;
    }
  }

  section#releases {
    padding: 0 20%;
    h1 {
      font-size: 3rem;
    }
  }
}
