:root {
  --black: #000000;
  --omgAreYouSeriousBlack: #1B1C1D;
  --blackTwo: #272727;
  --warmGrey: #787878;
  --greyishBrown: #4a4a4a;
  --warmGreyTwo: #9b9b9b;
  --white: #f0f0f0;
  --duskBlue: #2a589f;
  --whiteTwo: #ffffff;
  --azure: #0a95e0;
  --trueGreen: #0ea700;
  --vermillion: #ec1515;
  --dustyOrange: #f58b23;
  --deepGreen: #085b01;
  --yellow: yellow;
  --rosyPink: #f1657f;
  --pinkishGrey: #c4c4c4;
  --red: red;
}

section.app.dark {
  /*background-color: var(--blackTwo);*/
}

section.app.light {
  background-color: var(--white);
}

.weedResult h1 {
  color: var(--warmGrey);
}

p {
  color: var(--black)
}

section.app.white {
  background-color: white;
}
