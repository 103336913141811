/* sample page */
.exportButton.reportButton{
  margin-top: 5px
}

.labeledIcon span {
  vertical-align: middle;
  font-size: 1.1rem;
}

button.ui.button {
  border-radius: 1.5em;
}

.ui.button, .ui.button:active, .ui.button:hover {
  /* color: var(--white);
  background: rgba(0, 229, 61, 1);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3);
  background: -moz-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 229, 61, 1)), color-stop(100%, rgba(14, 167, 0, 1)));
  background: -webkit-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%); */
}

.ui.button.calibrationButton {
  background-color: var(--duskBlue);
  color: white;
}

.runButton {
  margin: 1rem;
}

.samplePrep .button.ui {
  width: 65%;
  margin: 2rem 0;
}

.mainButton.ui.button {
  width: 100%;
  font-size: 1.5rem;
}

.greyButton.ui.button {
  background: grey;
}

.supportButton.ui.button {
  margin-top: 1rem;
  color: var(--whiteTwo);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#48b3e1+0,0095e4+100 */
  background: #48b3e1;  /* Old browsers */
  background: -moz-linear-gradient(top, #48b3e1 0%, #0095e4 100%);  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #48b3e1 0%, #0095e4 100%);  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #48b3e1 0%, #0095e4 100%);  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  width: 70%;
  font-weight: 100;
  padding: 0.8rem;
  text-transform: uppercase;
}

.supportButton.ui.labeled.icon.button>.icon {
  background-color: transparent;
}

button.user-inactive {
  background-color:red !important;
  color:white !important;
}

button.user-active {
  background-color:green !important;
  color:white !important;
}

.dynamicTableActionButton {
  border-radius: .28571429rem !important;
  padding: 0.5em !important;
}

.dynamicTableActionButton:active, .dynamicTableActionButton:focus, .dynamicTableActionButton:focus, .dynamicTableActionButton:hover {
  /* background: none !important; */
}


.ui.positive.button, .ui.positive.buttons .button{
  /* background-image: none !important; */
}

.ui.button.green:not(.inverted), .ui.button.green:active:not(.inverted), .ui.button.green:hover:not(.inverted) {
  color: var(--white) !important;
  background: rgba(0, 229, 61, 1) !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 229, 61, 1)), color-stop(100%, rgba(14, 167, 0, 1))) !important;
  background: -webkit-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -o-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -ms-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: linear-gradient(to bottom, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
}

.ui.button.red, .ui.button.red:active, .ui.button.red:hover {
  color: var(--white) !important;
  background: #db2828 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #db2828), color-stop(100%, #b42121)) !important;
  background: -webkit-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: -o-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: -ms-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: linear-gradient(to bottom, #db2828 0%, #b42121 100%) !important;
}

.ui.button.blue, .ui.button.blue:active, .ui.button.blue:hover, .ui.button.blue:disabled {
  color: var(--white) !important;
  background: #2185d0 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #2185d0), color-stop(100%, #19629b)) !important;
  background: -webkit-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -o-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -ms-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: linear-gradient(to bottom, #2185d0 0%, #19629b 100%) !important;
}

.ui.button.blue:disabled {
  opacity: 0.45 !important;
}

.dataTableHeaderButtons > .button {
  margin-top: 5px !important;
}


.ui.button.confirmButton, .ui.button.confirmButton:active, .ui.button.confirmButton:hover, .ui.button.confirmButton:disabled {
  color: var(--white) !important;
  background: #2185d0 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #2185d0), color-stop(100%, #19629b)) !important;
  background: -webkit-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -o-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -ms-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: linear-gradient(to bottom, #2185d0 0%, #19629b 100%) !important;
}

.ui.button.testButton, .ui.button.testButton:active, .ui.button.testButton:hover, .ui.button.testButton:disabled {
  color: var(--white) !important;
  background: rgba(0, 229, 61, 1) !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 229, 61, 1)), color-stop(100%, rgba(14, 167, 0, 1))) !important;
  background: -webkit-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -o-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -ms-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: linear-gradient(to bottom, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
}

.ui.button.calibrationButton, .ui.button.calibrationButton:active, .ui.button.calibrationButton:hover, .ui.button.calibrationButton:disabled {
  color: var(--white) !important;
  background: #2185d0 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #2185d0), color-stop(100%, #19629b)) !important;
  background: -webkit-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -o-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -ms-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: linear-gradient(to bottom, #2185d0 0%, #19629b 100%) !important;
}

.ui.button.reportButton, .ui.button.reportButton:active, .ui.button.reportButton:hover, .ui.button.reportButton:disabled {
  color: var(--white) !important;
  background: #2185d0 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #2185d0), color-stop(100%, #19629b)) !important;
  background: -webkit-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -o-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -ms-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: linear-gradient(to bottom, #2185d0 0%, #19629b 100%) !important;
}

.ui.button.cameraButton, .ui.button.cameraButton:active, .ui.button.cameraButton:hover, .ui.button.cameraButton:disabled {
  color: var(--white) !important;
  background: #2185d0 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #2185d0), color-stop(100%, #19629b)) !important;
  background: -webkit-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -o-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: -ms-linear-gradient(top, #2185d0 0%, #19629b 100%) !important;
  background: linear-gradient(to bottom, #2185d0 0%, #19629b 100%) !important;
}

.ui.button.cancelButton, .ui.button.cancelButton:active, .ui.button.cancelButton:hover {
  color: var(--white) !important;
  background: #db2828 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #db2828), color-stop(100%, #b42121)) !important;
  background: -webkit-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: -o-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: -ms-linear-gradient(top, #db2828 0%, #b42121 100%) !important;
  background: linear-gradient(to bottom, #db2828 0%, #b42121 100%) !important;
}

.ui.button.userButton, .ui.button.userButton:active, .ui.button.userButton:hover, .ui.button.userButton:disabled {
  color: var(--white) !important;
  background: rgba(0, 229, 61, 1) !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 229, 61, 1)), color-stop(100%, rgba(14, 167, 0, 1))) !important;
  background: -webkit-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -o-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: -ms-linear-gradient(top, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
  background: linear-gradient(to bottom, rgba(0, 229, 61, 1) 0%, rgba(14, 167, 0, 1) 100%) !important;
}

.ui.button.calibration:disabled {
  opacity: 0.45 !important;
}



.ui.button.machineButton, .ui.button.machineButton:active, .ui.button.machineButton:hover, .ui.button.machineButton:disabled {
  color: var(--white) !important;
  background: #f2711c !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #f2711c), color-stop(100%, #cc6018)) !important;
  background: -webkit-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: -o-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: -ms-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: linear-gradient(to bottom, #f2711c 0%, #cc6018 100%) !important;
}

.ui.button.machineButton:disabled {
  opacity: 0.45 !important;
}

.ui.button.systemButton, .ui.button.systemButton:active, .ui.button.systemButton:hover, .ui.button.systemButton:disabled {
  color: var(--white) !important;
  background: #818386 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #818386), color-stop(100%, #535557)) !important;
  background: -webkit-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: -o-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: -ms-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: linear-gradient(to bottom, #818386 0%, #535557 100%) !important;
}
.ui.button.orange, .ui.button.orange:active, .ui.button.orange:hover, .ui.button.orange:disabled {
  color: var(--white) !important;
  background: #f2711c !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #f2711c), color-stop(100%, #cc6018)) !important;
  background: -webkit-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: -o-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: -ms-linear-gradient(top, #f2711c 0%, #cc6018 100%) !important;
  background: linear-gradient(to bottom, #f2711c 0%, #cc6018 100%) !important;
}

.ui.button.orange:disabled {
  opacity: 0.45 !important;
}

.ui.button.yellow, .ui.button.yellow:active, .ui.button.yellow:hover, .ui.button.yellow:disabled {
  color: var(--white) !important;
  background: #fbbd08 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #fbbd08 0%, #ce9c07 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fbbd08), color-stop(100%, #ce9c07)) !important;
  background: -webkit-linear-gradient(top, #fbbd08 0%, #ce9c07 100%) !important;
  background: -o-linear-gradient(top, #fbbd08 0%, #ce9c07 100%) !important;
  background: -ms-linear-gradient(top, #fbbd08 0%, #ce9c07 100%) !important;
  background: linear-gradient(to bottom, #fbbd08 0%, #ce9c07 100%) !important;
}

.ui.button.yellow:disabled {
  opacity: 0.45 !important;
}

.ui.button.black, .ui.button.black:active, .ui.button.black:hover, .ui.button.black:disabled {
  color: var(--white) !important;
  background: #818386 !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  background: -moz-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #818386), color-stop(100%, #535557)) !important;
  background: -webkit-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: -o-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: -ms-linear-gradient(top, #818386 0%, #535557 100%) !important;
  background: linear-gradient(to bottom, #818386 0%, #535557 100%) !important;
}

.ui.button.black:disabled {
  opacity: 0.45 !important;
}

.dashboardButton {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3) !important;
  cursor: pointer;
  text-transform: uppercase !important;
}

.dashboardButton:active, .dashboardButton:focus, .dashboardButton:focus, .dashboardButton:hover {
  /* background: none !important; */
}

.exportButton {
  padding: .78571429em 1.5em .78571429em !important;
}

.ButtonMargin {
  margin-bottom:1em !important;
}

.ui.button.changeButton {
  float:left;
  margin : 4px;
  margin-left : 25px;
  text-align : center;
}

/* Icon is the hamburger button */
.iconLink {
  position: relative;
  padding-left: 1.5em;
}

.iconLink i {
  position: absolute;
  left: 0;
}

.iconLink:hover span {
  text-decoration: underline;
}

.sideBarIcon {
  line-height: 22px !important;
  font-size: 24px !important;
}

.logoutMobile {
  font-size: 20px !important;
  font-weight: bold !important;
  margin-left: 1.5em !important;
}

.logoutMobile:hover {
  color: var(--red)
}

.closeModalButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.cameraButton.close {
  margin-top: 5px !important;
}

.cameraButton.ui.button {
  background: var(--duskBlue) !important;
  color: white;
}

/* UI CSS  30-10-2019 */
.screen .ui.centered.grid .attached.segment button.labeled {
  margin-top: 15px;
}

.compareReport.ui.button.disabled:hover { 
  pointer-events: all !important;
}

.compareReport.ui.button.disabled {
  pointer-events: auto !important;
}

.dashboardButtonGroup {
  background-color: var(--white);
  border-radius: 1rem;
  height: 100%;
}

#zsiqscript{
  bottom: 0;
  right:0;
  position: fixed;
}

#zsiqbtn {
  bottom: 0;
  right:0;
  opacity: .9;
  position: fixed;
}

#zsiqbtn:hover {
  opacity: 1;
}

.logout_other_devices {
  color: #383838;
  font-size: 1.2rem !important;
  padding: 1rem 0 !important;
  padding-left: 2rem;
  border-bottom: 1px solid #ccc;
}

.logout_other_devices:hover {
  background-color: #DEDEDE;
  cursor: pointer;
}

#newSampleTestMobile .confirmButton{
  border-radius: 0;
}

@media only screen and (max-width:480px) {
    /* Header */
  .logoutMobile {
    width: 20px;
    float: right;
    position: absolute;
    right: -20px;
    z-index: 1;
  }
}
@media only screen and (max-device-width: 1024px) {
  .dynamicTableActionButton {
    margin-top: 5px !important; 
  }
 }

 @media only screen and (max-width:480px) {
  .logoutMobile {
    width: 20px;
    float: right;
    position: absolute;
    right: -20px;
    z-index: 1;
  }
}

@media only screen and (min-width: 426px) {
  .zsiq_flt_rel1 {
    width: 300px !important;
  }
}

@media only screen and (max-device-width: 425px) {
  .ui.small.icon.none.floated.left.labeled.button.blue {
    width: 100%;
    float: right;
    margin-top:2px;
  } 
}

