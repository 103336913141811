.error p, p.error {
  color: var(--red) !important;
  font-size: 12px !important;
  margin-top: 3px !important;
}

.success {
  color: var(--deepGreen);
  font-size: 12px;
  margin-top: 3px;
}

.errorMessagesGrid {
  margin-bottom: 0.5rem !important;
}

.error .content p {
  color: #912d2b !important;
}

.theBadNews {
  color: var(--black);
  font-size: 2rem;
  padding: 2rem 0 3rem;
  line-height: 2;
  width: 50%;
  margin: 0 auto;
}

.recommendation {
  border:3px solid yellow !important;
  border-radius: 1rem !important;
}

.error{
  color:red;
}

.bottleWarning {
  background: magenta;
}