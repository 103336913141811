#legend-item-container .symbol {
  width: 11px;
  height: 11px;
  margin-right:5px;
  float:left;
}

#legend-item-container .serieName {
  float:left;
  cursor:pointer;
  color: #000000;
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
}

#legend-item-container .item {
  clear:both;
  margin: 0 10px 0 10px;
  display: inline-flex;
  width: 55px;
}

#legend-item-container .disabled {
  opacity: 0.5;
  text-decoration: line-through;
}

#accordEqua {
  color: black;
}
