div.BottleWidget {
  float : left;
  color: black;
  
}
.bottleDiv {
  width: 47%;
  float: left;
  margin: 4px;
}
.bottleDiv.mini {
  width: auto;
  float: none;
}
.botUsage {
  height : 135px;
  min-width : 160px;
  background : #EFEFEF;
}
.botUsage.mini {
  height : auto;
  min-width : auto;
  background : #EFEFEF;
}

.ui.input.bottleLimit, .ui.input.bottleCurrent {
  float : left;
  width : 60%;
  padding : 4px;
  padding-left : 15px;
}

/* bottle capacity text */
div.botUsage > svg > text{
  font: bold 80px sans-serif;
  text-shadow: -.75px 0 rgba(255,255,255,.65), 0 .75px rgba(255,255,255,.65), .75px 0 rgba(255,255,255,.65), 0 -.75px rgba(255,255,255,.65);
}